import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../components/Button.jsx';
import handlerPopup from '../../../functions/handlerPopup';
import Link from '../../../components/Link.jsx';

class ProfileContentMarks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        // const { cheques } = this.state;
        // const { setUpdateKey, components } = this.props;
        const { components } = this.props;
        const marks = components?.marks;

        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">
                        {marks?.header?.header?.description}
                    </div>
                </div>
                <div className={`profileContent__pageContent ${components ? '_ready' : ''}`}>
                    <div className="profileContent__marks">
                        <div className="profileContent__mark">
                            <div className="profileCard">
                                <div className="profileCard__title">
                                    {marks?.blocks?.regcheck?.title}
                                </div>
                                <img
                                    src={marks?.blocks?.regcheck?.thumb}
                                    alt=""
                                    className="profileCard__image"
                                />
                                <Link className="profileCard__button" pageName="cheques">
                                    <Button className="_purple">
                                        {marks?.blocks?.regcheck?.btnTitle}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="profileContent__mark">
                            <div className="profileCard">
                                <div className="profileCard__title">
                                    {marks?.blocks?.referal?.title}
                                </div>
                                <img
                                    src={marks?.blocks?.referal?.thumb}
                                    alt=""
                                    className="profileCard__image"
                                />
                                <div className="profileCard__button">
                                    <Button
                                        className="_purple"
                                        onClick={() => {
                                            handlerPopup({ name: 'referalPopup', isShow: true });
                                        }}
                                    >
                                        {marks?.blocks?.referal?.btnTitle}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentMarks);

ProfileContentMarks.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
    components: PropTypes.object,
};
