import axios from 'axios';
import getHeaders from './getHeaders';
import logoutActions from './logoutActions';

export default function logout() {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/api/Logout`, { headers: getHeaders() })
            .then((res) => {
                const { result } = res.data;

                if (result === 'OK') {
                    logoutActions();
                }

                resolve();
            }, reject);
    });
}
