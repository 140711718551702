import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../../components/Table.jsx';

class ProfileContentPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkInit() {
        const { privateContent, setUpdateKey } = this.props;

        if (privateContent && !this.isReady) {
            this.isReady = true;

            this.setState(
                {
                    cheques: privateContent.checks,
                },
                () => {
                    setUpdateKey();
                },
            );
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    render() {
        const { cheques } = this.state;
        const { setUpdateKey } = this.props;

        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">Мои чеки</div>
                </div>
                <div className="profileContent__pageContent">
                    <div className="profileContent__table">
                        <Table
                            name="cheques"
                            items={cheques || []}
                            isReady={!!cheques}
                            callback={() => {
                                setUpdateKey();
                            }}
                            emptyTitle="Чеков пока нет"
                        />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentPrizes);

ProfileContentPrizes.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
    components: PropTypes.object,
};
