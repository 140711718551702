const pages = [
    {
        links: ['404'],
        name: '404',
        content: '404',
    },
    {
        links: ['', undefined],
        name: 'index',
        content: 'Главная',
    },
    {
        links: ['about'],
        name: 'about',
        content: 'о продукте',
    },
    {
        links: ['winners'],
        name: 'winners',
        content: 'победители',
    },
    {
        links: ['faq'],
        name: 'faq',
        content: 'faq',
    },
    {
        links: ['login'],
        name: 'login',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['registration'],
        name: 'registration',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['cheques'],
        name: 'cheques',
        mainPage: (reduxStore) => (reduxStore?.user ? 'profile' : 'index'),
        isPopup: true,
    },
    {
        links: ['anket'],
        name: 'anket',
        content: 'anket',
    },
    {
        links: ['profile'],
        name: 'profile',
        content: 'profile',
    },
    {
        links: ['', undefined],
        name: 'profile-marks',
        content: 'Баллы',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['prizes'],
        name: 'profile-prizes',
        content: 'призы',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['cheques'],
        name: 'profile-cheques',
        content: 'чеки',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['game'],
        name: 'profile-game',
        content: 'Игра',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['game'],
        name: 'game',
        content: 'Игра',
    },
    {
        links: ['full-anket'],
        name: 'fullAnket',
        content: 'Анкета',
    },
    {
        links: ['anketForm'],
        name: 'anketForm',
    },
    {
        links: ['actUpload'],
        name: 'act',
        content: 'Акт',
    },
    {
        links: [],
        name: 'act-inner',
        level: 1,
        parentName: 'act',
    },
];

export default pages;
