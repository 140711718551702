import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../functions/setSpacesInText';
import changePage from '../functions/changePage';
import { dispatcher } from '../redux/redux';
// import setSpacesInText from '../functions/setSpacesInText';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { mainContent } = this.props;
        const footer = mainContent?.footer;

        return (
            <>
                <div ref={this.parent} className="footer">
                    <div className="footer__inner">
                        <div className="footer__nav">
                            <a
                                href={footer?.url2?.url}
                                className="footer__navLink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {footer?.url2?.title}
                            </a>
                            <a
                                href={footer?.url3?.url}
                                className="footer__navLink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {footer?.url3?.title}
                            </a>
                            <a
                                href="#"
                                className="footer__navLink"
                                onClick={(e) => {
                                    e.preventDefault();

                                    dispatcher({
                                        type: 'ancorToFaqForm',
                                        data: true,
                                    }).then(() => {
                                        changePage({ href: 'faq' });
                                    });
                                }}
                            >
                                {footer?.url1?.title}
                            </a>
                        </div>
                        <div
                            className="footer__content"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(footer?.disclaimer?.description),
                            }}
                        ></div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        mainContent: state.mainContent,
    };
}

export default connect(mapStateToProps)(Footer);

Footer.propTypes = {
    device: PropTypes.string,
    waitMode: PropTypes.string,
    mainContent: PropTypes.object,
};
