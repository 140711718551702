import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Page from './Page.jsx';
import setSpacesInText from '../functions/setSpacesInText';

class WaitHeader extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pageName = 'soon';

    contentName = 'soon';

    render() {
        const { components } = this.state;
        const counter = components?.counter?.days;

        return (
            <>
                <div ref={this.parent} className={`waitHeader ${components ? '_ready' : ''}`}>
                    <div className="waitHeader__inner">
                        <div className="waitHeader__head">
                            <img
                                src={require('../media/logo.svg').default}
                                alt=""
                                className="waitHeader__logo"
                            />
                            <div className="waitHeader__timer">
                                <div className="waitHeader__timerHead">
                                    <p className="waitHeader__timerSupport">
                                        {components?.counter?.title}
                                    </p>
                                    <div className="waitHeader__timerCounter">
                                        <span>{counter}</span>
                                        {components?.counter?.daysTitle}
                                    </div>
                                </div>
                                <div className="waitHeader__timerDate">
                                    <span>Дата старта -</span> <span>1 августа 2024</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="waitHeader__button">Правила акции</div> */}
                        <div className="waitHeader__footer">
                            <div
                                className="waitHeader__footerInner"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        components?.footer?.disclaimer?.description,
                                    ),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(WaitHeader);

WaitHeader.propTypes = {
    device: PropTypes.string,
};
