import { dispatcher } from '../redux/redux';
import changePage from './changePage';
import { deleteCookie } from './handlerCookies';

export default function logoutActions() {
    changePage({ href: '' });

    deleteCookie(process.env.REACT_APP_HASH);
    localStorage.removeItem(process.env.REACT_APP_USER);

    setTimeout(() => {
        dispatcher({ type: 'user', data: null });
    }, 300);
}
