import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import setSpacesInText from '../functions/setSpacesInText';

class MarksPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    render() {
        const { marksPopup } = this.props;
        const { items = [] } = marksPopup;

        return (
            <Popup name="marksPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Накопленные баллы</div>
                </div>
                <div className="popup__content">
                    <div className="popup__table">
                        <div className="popup__tableRow _head">
                            <div className="popup__tableCol _date">Дата</div>
                            <div className="popup__tableCol _counter">Баллы</div>
                            <div className="popup__tableCol _comment">Комментарий</div>
                        </div>
                        <div className="popup__tableContent">
                            {items.map((item, key) => (
                                <div className="popup__tableRow" key={key}>
                                    <div className="popup__tableCol _date">{item.datetime}</div>
                                    <div
                                        className={`popup__tableCol _counter ${
                                            +item.sum < 0 ? '_negative' : ''
                                        }`}
                                    >
                                        {item.sum}
                                    </div>
                                    <div
                                        className="popup__tableCol _comment"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(item.comment || '–'),
                                        }}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__buttons">
                        <div className="popup__button _auto">
                            <Button
                                className="_purple"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'marksPopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                ОК
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        marksPopup: state.marksPopup,
    };
}

export default connect(mapStateToProps)(MarksPopup);

MarksPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    marksPopup: PropTypes.object,
};
