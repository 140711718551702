import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components } = this.props;
        const header = components?.anounce;
        const game = components?.game;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexHeader ${components ? '_ready' : ''}`}
                    id="index"
                >
                    <div className="indexHeader__inner">
                        <div className="indexHeader__slogan _1"></div>
                        <div className="indexHeader__buttons">
                            <a
                                className="indexHeader__button"
                                href={header?.buttons?.button1?.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="_blue">
                                    {header?.buttons?.button1?.title || '–'}
                                </Button>
                            </a>
                        </div>
                        <div className="indexHeader__content">
                            <div className="indexHeader__contentTitle">
                                Срок регистрации в&nbsp;Акции завершен
                            </div>
                            <p className="indexHeader__contentDescription">
                                Призы победителям будут отправлены в срок до 31.12.2024. Уточнить
                                детали доставки можно в личном кабинете или в форме обратной связи.
                            </p>
                            <p className="indexHeader__contentDescription">
                                Продолжай играть во Френдзанию!
                            </p>
                            <a
                                className="indexHeader__contentButton"
                                href={game?.button?.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="_yellowLight">ИГРАть</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
