import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Button from './Button.jsx';
import Checkbox from './Checkbox.jsx';
import Select from './Select.jsx';

import getHeaders from '../functions/getHeaders';
import handlerLoading from '../functions/handlerLoading';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import handlerPopup from '../functions/handlerPopup';
import saveJWT from '../functions/saveJWT';
import getEndText from '../functions/getEndText';
import setSpacesInText from '../functions/setSpacesInText';

class MarksPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.setUpdateKey = this.setUpdateKey.bind(this);

        this.parent = React.createRef();
    }

    blocks = {
        start: {
            render() {
                const { currentSize, isConfirm, error, loadingKey } = this.state;
                const { merchPopup } = this.props;
                const { merch } = merchPopup;

                return (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Заказ мерча</div>
                        </div>
                        <div className="popup__content">
                            <div className="popup__merch">
                                <div className="popup__merchHead">
                                    <img src={merch?.thumb} alt="" className="popup__merchImage" />
                                    <div className="popup__merchCount">
                                        <div className="popup__merchCountTitle">{merch?.price}</div>
                                        {getEndText(merch?.price, ['балл', 'балла', 'баллов'])}
                                    </div>
                                </div>
                                {merch?.tips && (
                                    <div className="popup__merchTips">
                                        <div className="popup__merchTipsContent">{merch.tips}</div>
                                        <div className="popup__merchTipsInfo">
                                            i
                                            <div
                                                className="popup__merchTipsInfoAlert"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(merch.tipsFull),
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                )}
                                {merch?.sizes && (
                                    <div className="popup__merchSize">
                                        <p className="popup__merchSizeSupport">Размер:</p>
                                        <div className="popup__merchSizeSelect">
                                            <Select
                                                value={currentSize}
                                                support="–"
                                                list={merch?.sizes.map((item) => ({
                                                    key: item,
                                                    content: item,
                                                }))}
                                                onChange={async ({ value }) => {
                                                    this.setState({ currentSize: value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="popup__merchConfirm">
                                    <Checkbox
                                        checked={isConfirm}
                                        onChange={() => {
                                            this.setState({ isConfirm: !isConfirm });
                                        }}
                                    >
                                        Подтверждаю заказ
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error })}
                            <div className="popup__buttons">
                                <div className="popup__button _auto">
                                    <Button
                                        className="_purple"
                                        onClick={this.send.bind(this)}
                                        loader={loadingKey === 'send'}
                                    >
                                        Заказать
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
        },
        success: {
            render() {
                const { merchPopup } = this.props;
                const { merch } = merchPopup;

                return (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Заказ мерча</div>
                        </div>
                        <div className="popup__content">
                            <div className="popup__merch">
                                <div className="popup__merchHead">
                                    <img src={merch?.thumb} alt="" className="popup__merchImage" />
                                </div>
                                <div className="popup__merchSuccess">
                                    Приз {`"${merch.title}"`} успешно заказан
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            <div className="popup__buttons">
                                <div className="popup__button _auto">
                                    {/* <Button
                                        className="_purple"
                                        onClick={() => {
                                            handlerPopup({ name: 'merchPopup', isShow: false });
                                        }}
                                    >
                                        Заполнить адрес для&nbsp;доставки приза
                                    </Button> */}
                                    <Button
                                        className="_purple"
                                        onClick={() => {
                                            handlerPopup({ name: 'merchPopup', isShow: false });
                                        }}
                                    >
                                        ОК
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    setUpdateKey() {
        this.setState({ renderUpdateKey: new Date().getTime() });
    }

    renderBlock({ prop: id }) {
        const block = this.blocks[id];

        return <div className="popup__block">{block.render.call(this)}</div>;
    }

    async send() {
        const { currentSize, isConfirm } = this.state;
        const { merchPopup } = this.props;
        const { merch } = merchPopup;
        let prize = merch.code;

        if (!isConfirm) {
            this.setState({ error: 'Необходимо подтвердить заказ' });

            return;
        }

        if (merch.sizes && !currentSize) {
            this.setState({ error: 'Необходимо выбрать размер' });

            return;
        }

        if (currentSize) {
            prize += `-${currentSize}`;
        }

        await handlerLoading.call(this, 'send', { error: null });

        try {
            const response = await axios.post(
                `/api/AddUserPrize`,
                { prize },
                { headers: getHeaders() },
            );

            const { result, JWT } = response.data;

            saveJWT(JWT);

            if (result === 'OK') {
                this.setState({ currentBlock: 'success' });

                document.dispatchEvent(new CustomEvent('updateProfile'));
            }
        } catch (error) {
            console.log(error);

            this.setState({ error: error.response?.data?.errorText });
        }

        await handlerLoading.call(this, null);
    }

    isPrivate = true;

    render() {
        const { currentBlock, renderUpdateKey } = this.state;

        return (
            <Popup name="merchPopup">
                <ListAbsoluteMain
                    className="popup__blocks"
                    items={[{ id: currentBlock }]}
                    renderItem={this.renderBlock.bind(this)}
                    classNameItem="popup__block"
                    prop="id"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    currentItemKey={currentBlock}
                    allItems={['start', 'success']}
                    resizeParent={document.querySelector('.body')}
                    keyRender={renderUpdateKey}
                />
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        merchPopup: state.merchPopup,
    };
}

export default connect(mapStateToProps)(MarksPopup);

MarksPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    merchPopup: PropTypes.object,
};
