import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from '../components/Popup.jsx';
import Field from '../components/Field.jsx';
import Link from '../components/Link.jsx';
import Button from '../components/Button.jsx';
import ListAbsoluteMain from '../components/ListAbsoluteMain.jsx';
import saveJWT from '../functions/saveJWT';
import changePage from '../functions/changePage';
import Loader from '../components/Loader.jsx';
import Animate from '../components/Animate.jsx';
import { dispatcher } from '../redux/redux';
import checkAuth from '../functions/checkAuth';
import handlerPopup from '../functions/handlerPopup';
import getHeaders from '../functions/getHeaders';

class Registration extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: localStorage.getItem('regEmail') ? 'code' : 'start',
            codes: [],
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.registration = this.registration.bind(this);

        this.isPrivate = !!this.props.isEmail;

        this.parent = React.createRef();
    }

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ id: currentBlock }];
    }

    getDescription() {
        const { device } = this.props;

        return device === 'mobile' ? (
            <>если вы еще не регистрировались или&nbsp;забыли пароль</>
        ) : (
            <>
                если вы еще не регистрировались или&nbsp;забыли пароль
                <br />
                для входа в личный кабинет
            </>
        );
    }

    renderBlock({ prop: id }) {
        const { fields, loginError, codeError, mailService, codes, loadingKey } = this.state;
        const { device, isEmail } = this.props;
        const ServiceTag = mailService ? 'a' : React.Fragment;

        return (
            <div className="popup__block">
                {id === 'start' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">
                                {isEmail ? (
                                    <>Необходимо подтвердить Email</>
                                ) : (
                                    <>Вход в Личный кабинет</>
                                )}
                            </div>
                            {!isEmail && (
                                <p className="popup__headDescription">{this.getDescription()}</p>
                            )}
                        </div>
                        <div className="popup__content">
                            <div className="popup__fields">
                                <div className="popup__field">
                                    <Field
                                        support={
                                            device === 'mobile'
                                                ? 'E-mail (Ваш логин)'
                                                : 'Адрес электронной почты (Ваш логин)'
                                        }
                                        name="email"
                                        value={fields?.email}
                                        onChange={this.handlerField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error: loginError })}
                            <div className="popup__buttons">
                                {!isEmail && (
                                    <Link href="login" className="popup__button">
                                        <Button className="_emptyPurple">Личный кабинет</Button>
                                    </Link>
                                )}
                                <div className="popup__button">
                                    <Button
                                        className="_purple"
                                        onClick={() => {
                                            this.registration({});
                                        }}
                                        loader={loadingKey === 'login'}
                                    >
                                        {isEmail ? 'получить код' : 'получить пароль'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {id === 'code' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">
                                {isEmail ? (
                                    <>Необходимо подтвердить Email</>
                                ) : (
                                    <>получение пароля</>
                                )}
                            </div>
                            {!isEmail && (
                                <p className="popup__headDescription">{this.getDescription()}</p>
                            )}
                        </div>
                        <div className="popup__content">
                            <div className="popup__codes">
                                <div className="popup__codesDescription">
                                    Вам отправлено сообщение на адрес:{' '}
                                    <span>{this.state.regEmail}</span>{' '}
                                    <ServiceTag href={mailService} target="_blank">
                                        Проверьте почтовый ящик
                                    </ServiceTag>{' '}
                                    и&nbsp;введите код {!isEmail ? 'для сброса пароля' : ''} ниже
                                </div>
                                <div
                                    className={`popup__codesInputs ${
                                        loadingKey === 'code' ? '_loading' : ''
                                    }`}
                                >
                                    <Animate
                                        className="popup__codesInputsLoader"
                                        isShow={loadingKey === 'code'}
                                    >
                                        <div className="popup__codesInputsLoaderItem">
                                            <Loader />
                                        </div>
                                    </Animate>
                                    <div className="popup__codesInputsInner">
                                        {[0, 1, 2, 3, 4, 5].map((key) => (
                                            <input
                                                className="popup__codesInput"
                                                type="text"
                                                key={key}
                                                data-key={key}
                                                value={codes[key]}
                                                onChange={this.handlerCode.bind(this, key)}
                                                disabled={loadingKey === 'code'}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error: codeError })}
                            <div className="popup__buttons">
                                <div className="popup__button _auto">
                                    <Button
                                        className="_purple"
                                        onClick={() => {
                                            this.setState({ currentBlock: 'start' });
                                        }}
                                    >
                                        Отправить код повторно
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    handlerCode(key, { target }) {
        const value = target.value.replace(/[^\d]/gi, '');
        const inputs = [key];

        for (let i = key + 1; i < value.length; i++) {
            inputs.push(i);
        }

        this.setState(
            (state) => {
                const newState = { ...state };
                const codes = [...newState.codes];

                inputs.forEach((resultKey) => {
                    codes[resultKey] = value[resultKey - key];
                });

                newState.codes = codes;

                return newState;
            },
            () => {
                let nextInput = document.querySelector(
                    `.popup__codesInput[data-key="${
                        +inputs[inputs.length - 1] + (+inputs[inputs.length - 1] === 5 ? 0 : 1)
                    }"]`,
                );

                if (!value) {
                    nextInput = document.querySelector(`.popup__codesInput[data-key="${key - 1}"]`);
                }

                if (nextInput) {
                    nextInput.focus();
                }

                if (this.state.codes.join('').length === 6) {
                    this.registration({ isCode: true });
                }
            },
        );
    }

    registration({ isCode }) {
        const { fields, codes } = this.state;
        const { isEmail } = this.props;
        const code = codes.join('');
        const login = fields?.email || this.state.regEmail;

        this.handlerLoading(isCode ? 'code' : 'login').then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/${isCode ? 'Login' : 'Registration'}`,
                    {
                        login,
                        ...(isCode ? { password: code, isCode: true } : {}),
                        ...(isEmail ? { confirmEmail: true } : {}),
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { result, data } = res.data;

                        if (result === 'OK') {
                            if (!isCode) {
                                this.handlerLoading(null);

                                const { email, mailService } = data;

                                this.setState(
                                    { regEmail: email, mailService, currentBlock: 'code' },
                                    () => {
                                        localStorage.setItem('regEmail', email);
                                    },
                                );
                            } else {
                                const { JWT } = res.data;

                                dispatcher({ type: 'isAuthProccessed', data: true }).then(() => {
                                    saveJWT(JWT);

                                    checkAuth().then((user) => {
                                        this.handlerLoading(null);

                                        if (user) {
                                            localStorage.removeItem('regEmail');

                                            changePage({
                                                href:
                                                    user.status === 'ANKET_REQUIRED'
                                                        ? 'anket'
                                                        : 'profile',
                                            });

                                            if (isEmail) {
                                                handlerPopup({ name: 'emailPopup', isShow: false });
                                            }

                                            setTimeout(() => {
                                                dispatcher({
                                                    type: 'isAuthProccessed',
                                                    data: false,
                                                });
                                            }, 500);
                                        } else {
                                            this.setState({ loginError: 'Ошибка авторизации' });

                                            dispatcher({
                                                type: 'isAuthProccessed',
                                                data: false,
                                            });
                                        }
                                    });
                                });
                            }
                        }
                    },
                    (err) => {
                        this.handlerLoading(null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ [isCode ? 'codeError' : 'loginError']: errorText });
                        } catch (error) {
                            this.setState({
                                [isCode ? 'codeError' : 'loginError']: 'Ошибка сервера',
                            });
                        }
                    },
                );
        });
    }

    componentDidMount() {
        const { user, isEmail } = this.props;

        if (localStorage.getItem('regEmail')) {
            this.setState({
                regEmail: localStorage.getItem('regEmail'),
            });
        }

        if (isEmail && user) {
            this.setState({ fields: { email: user.personal?.email } });
        }
    }

    render() {
        const { currentBlock, renderUpdateKey } = this.state;
        const { isEmail } = this.props;

        return (
            <>
                <Popup name={isEmail ? 'emailPopup' : undefined}>
                    <ListAbsoluteMain
                        className="popup__blocks"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="popup__block"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentBlock}
                        allItems={['start', 'code']}
                        resizeParent={document.querySelector('.body')}
                        keyRender={renderUpdateKey}
                    />
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        isAuthProccessed: state.isAuthProccessed,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Registration);

Registration.propTypes = {
    device: PropTypes.string,
    isAuthProccessed: PropTypes.bool,
    isEmail: PropTypes.bool,
    user: PropTypes.object,
};
