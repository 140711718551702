import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import FormPage from '../../components/FormPage.jsx';
import handlerLoading from '../../functions/handlerLoading';
import getHeaders from '../../functions/getHeaders';

import checkAuth from '../../functions/checkAuth';
import saveJWT from '../../functions/saveJWT';
import requestSuccess from '../../functions/requestSuccess';
import changePage from '../../functions/changePage';

class ActForm extends FormPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.sendAnket = this.sendAnket.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['act', 'policy'];

    getFields() {
        const { user } = this.props;
        const fieldsOrder = [...this.fieldsOrder];

        if (user?.isFirstAnket) {
            fieldsOrder.unshift('referral');
        }

        return fieldsOrder;
    }

    fields = {
        act: {
            support: 'Скан подписанного акта*:',
            type: 'upload',
        },
        policy: {
            type: 'checkbox',
            support: () => (
                <>
                    Я соглашаюсь с{' '}
                    <a href="/upload/docs/politics-full.pdf" target="_blank" rel="noreferrer">
                        политикой конфиденциальности
                    </a>{' '}
                    <br className="_desktopMedia" />и{' '}
                    <a href="/upload/docs/agreement-full.pdf" target="_blank" rel="noreferrer">
                        условиями обработки персональных данных
                    </a>
                    *
                </>
            ),
        },
    };

    sendAnket() {
        const { fields } = this.state;
        const { levels } = this.props;

        this.formData.set('userPrizeId', levels[1]);
        this.formData.set('agreement', !!fields.policy);

        handlerLoading.call(this, true, { error: null }).then(() => {
            axios
                .post(`${process.env.REACT_APP_API}/api/SendParticipantAct`, this.formData, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        requestSuccess(res);

                        const { result, JWT } = res.data;

                        if (result === 'OK') {
                            saveJWT(JWT);

                            checkAuth().then(() => {
                                handlerLoading.call(this, null);

                                this.setSuccess();
                            });

                            // if (data?.isFirstAnket) {
                            //     if (window.YANDEX_ID) {
                            //         window[`yaCounter${window.YANDEX_ID}`].reachGoal('anket');
                            //     }
                            // }
                        } else {
                            handlerLoading.call(this, null);
                        }
                    },
                    (err) => {
                        handlerLoading.call(this, null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ error: errorText });
                        } catch (error) {
                            this.setState({
                                error: 'Ошибка сервера',
                            });
                        }
                    },
                );
        });
    }

    refresh(id) {
        const { getPrivateContent } = this.props;
        changePage({ href: `actUpload/${id}` });

        const fields = {};

        this.setState({ fields, isSuccess: false, isCompleteSuccess: false }, () => {
            getPrivateContent();
        });
    }

    componentDidMount() {
        super.componentDidMount();

        const { user } = this.props;

        if (user) {
            const fields = {};

            this.setState({ fields });
        }
    }

    render() {
        const {
            error,
            isSuccess = false,
            isCompleteSuccess,
            formHeight = 0,
            loadingKey,
        } = this.state;
        const { user, levels, privateContent } = this.props;
        const prizeId = levels[1];
        const otherPrize = prizeId
            ? privateContent?.prizes?.find(
                  (prize) => +prize.userPrizeId !== +prizeId && prize.status === 'ACT_REQUIRED',
              )
            : null;

        return (
            <>
                <div ref={this.parent} className="faqForm _anket">
                    <div className="faqForm__inner">
                        <div className="faqForm__content">
                            <div className="faqForm__contentInner">
                                <div
                                    className={`form ${isCompleteSuccess ? '_success' : ''}`}
                                    style={isSuccess ? { height: `${formHeight}px` } : {}}
                                >
                                    <Animate className="form__success" isShow={isSuccess}>
                                        <div className="form__successContent">
                                            <span>
                                                Спасибо, {user?.personal?.lastName}{' '}
                                                {user?.personal?.firstName}!
                                            </span>
                                            Акт успешно отправлен.{' '}
                                            {otherPrize ? (
                                                <>
                                                    <span
                                                        className="_link"
                                                        onClick={() => {
                                                            this.refresh(otherPrize.userPrizeId);
                                                        }}
                                                    >
                                                        Загрузите акт
                                                    </span>{' '}
                                                    для следующего приза.
                                                </>
                                            ) : (
                                                <>
                                                    Перейти в{' '}
                                                    <span
                                                        className="_link"
                                                        onClick={() => {
                                                            changePage({ href: 'profile' });
                                                        }}
                                                    >
                                                        Личный кабинет
                                                    </span>
                                                    .
                                                </>
                                            )}
                                        </div>
                                    </Animate>
                                    <div className="form__inner">
                                        <div className="form__fields">
                                            {this.getFields().map((name) =>
                                                this.renderField({ name }),
                                            )}
                                        </div>
                                        <div className="form__required">*Обязательные поля</div>
                                        <AnimateChange
                                            className="form__error _center"
                                            renderKey={error}
                                            paramsParent={{
                                                width: document.querySelector('.form__inner')
                                                    ?.offsetWidth,
                                            }}
                                            itemParams={['width']}
                                        >
                                            {error ? <div className="error">{error}</div> : null}
                                        </AnimateChange>
                                        <div className="form__button">
                                            <Button
                                                className="_purple"
                                                onClick={this.sendAnket}
                                                loader={!!loadingKey}
                                            >
                                                Сохранить данные
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ActForm);

ActForm.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
    levels: PropTypes.array,
    privateContent: PropTypes.object,
    getPrivateContent: PropTypes.func,
};
