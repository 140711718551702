import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Arrow from './Arrow.jsx';

import Slider from '../functions/Slider';
import handlerPopup from '../functions/handlerPopup';

class ProfilePrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMerch: 0,
        };

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    sliderInit() {
        const slider = this.parent.current;

        const callback = ({ current }) => {
            if (typeof current === 'number') {
                this.setState({ currentMerch: current });
            }
        };

        this.slider = new Slider({
            slider,
            lay: slider.querySelector('.profileCard__sliderItems'),
            items: slider.querySelectorAll('.profileCard__sliderItem'),
            infinity: true,
            withResize: true,
            // loop: 3_000,
            current: 0,
            buttons: {
                prev: slider.querySelector('.profileCard__sliderArrow._prev'),
                next: slider.querySelector('.profileCard__sliderArrow._next'),
            },
            callback,
        });
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            if (this.slider) {
                this.slider.clearLoop();
            }
        } else if (this.slider) {
            this.slider.setLoop();
        }
    }

    componentDidMount() {
        const { guaranteed } = this.props;

        if (!guaranteed) {
            this.sliderInit();
        }

        // document.addEventListener('visibilityChange', this.visibilityChange);
    }

    render() {
        const { currentMerch } = this.state;
        const { info, privateInfo, merch, guaranteed } = this.props;
        const title = merch
            ? merch.header?.title?.title
            : guaranteed
            ? guaranteed.title
            : info.title;
        let description = merch
            ? 'СТОИМОСТЬ УЧАСТИЯ - <span>5</span> БАЛЛОВ'
            : guaranteed
            ? guaranteed.subtitle
            : info.description;

        if (merch) {
            description = merch.items[currentMerch]?.priceTitle;
        }

        return (
            <>
                <div ref={this.parent} className="profileCard">
                    <div className="profileCard__content">
                        <div className="profileCard__title">
                            {title} {privateInfo?.date}
                        </div>
                        <div
                            className="profileCard__subTitle"
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></div>
                        {info?.subtitle && (
                            <div className="profileCard__description">{info.subtitle}</div>
                        )}

                        <div className="profileCard__slider">
                            {!guaranteed && (
                                <>
                                    <Arrow className="profileCard__sliderArrow _prev" name="prev" />
                                    <Arrow className="profileCard__sliderArrow _next" name="next" />
                                </>
                            )}

                            {merch ? (
                                <>
                                    <div className="profileCard__sliderItems">
                                        {merch.items.map((item, key) => (
                                            <div className="profileCard__sliderItem" key={key}>
                                                <div className="profileCard__sliderItemTips">
                                                    {item.tips}
                                                </div>
                                                <div className="profileCard__sliderItemHead">
                                                    <img
                                                        src={item.thumb}
                                                        alt=""
                                                        className="profileCard__sliderItemImage"
                                                    />
                                                </div>
                                                <div className="profileCard__sliderItemName">
                                                    {item.title}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : guaranteed ? (
                                <>
                                    <div className="profileCard__sliderItems">
                                        <div className="profileCard__sliderItem">
                                            <div className="profileCard__sliderItemHead">
                                                <img
                                                    src={guaranteed.thumb}
                                                    alt=""
                                                    className="profileCard__sliderItemImage"
                                                />
                                            </div>
                                            <div className="profileCard__sliderItemName">
                                                {guaranteed.description}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="profileCard__sliderItems">
                                        {info.thumbsExtra.map((item, key) => (
                                            <div className="profileCard__sliderItem" key={key}>
                                                <div className="profileCard__sliderItemHead">
                                                    <img
                                                        src={item[0]}
                                                        alt=""
                                                        className="profileCard__sliderItemImage"
                                                    />
                                                </div>
                                                <div className="profileCard__sliderItemName">
                                                    {item[1]}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        {privateInfo?.participateTitle && (
                            <div
                                className={`profileCard__status ${
                                    privateInfo?.participate ? '_success' : ''
                                }`}
                                dangerouslySetInnerHTML={{ __html: privateInfo.participateTitle }}
                            ></div>
                        )}
                    </div>
                    <div className="profileCard__foot">
                        {merch ? (
                            <>
                                <div className="profileCard__button">
                                    <Button
                                        className="_purple"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'merchPopup',
                                                isShow: true,
                                                merch: merch.items[currentMerch],
                                                currentMerch,
                                            });
                                        }}
                                    >
                                        Заказать
                                    </Button>
                                </div>
                            </>
                        ) : guaranteed ? (
                            <>
                                {guaranteed.guaranteed && (
                                    <img
                                        src={require('../media/prize-done.svg').default}
                                        alt=""
                                        className="profileCard__done"
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {privateInfo?.participate ? (
                                    <></>
                                ) : !privateInfo?.participateTitle ? (
                                    <>
                                        <div className="profileCard__button">
                                            <Button
                                                className="_purple"
                                                onClick={() => {
                                                    handlerPopup({
                                                        name: 'rufflePopup',
                                                        isShow: true,
                                                        raffle: privateInfo.id,
                                                        price: +info.price,
                                                    });
                                                }}
                                            >
                                                {info.button}
                                            </Button>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfilePrize);

ProfilePrize.propTypes = {
    id: PropTypes.string,
    info: PropTypes.object,
    privateInfo: PropTypes.object,
    merch: PropTypes.object,
    guaranteed: PropTypes.object,
};
