import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import FormPage from '../../components/FormPage.jsx';
import handlerLoading from '../../functions/handlerLoading';
import getHeaders from '../../functions/getHeaders';
import checkAuth from '../../functions/checkAuth';
import saveJWT from '../../functions/saveJWT';
import requestSuccess from '../../functions/requestSuccess';

class AnketForm extends FormPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.sendAnket = this.sendAnket.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = [
        'firstName',
        'secondName',
        'phone',
        'password',
        'againPassword',
        'policy',
        'mailing',
    ];

    fields = {
        policy: {
            type: 'checkbox',
            support: () => (
                <>
                    Я соглашаюсь с{' '}
                    <a href="/upload/docs/politics-full.pdf" target="_blank" rel="noreferrer">
                        политикой конфиденциальности
                    </a>{' '}
                    <br className="_desktopMedia" />и{' '}
                    <a href="/upload/docs/agreement-full.pdf" target="_blank" rel="noreferrer">
                        условиями обработки персональных данных
                    </a>
                    *
                </>
            ),
        },
    };

    handlerUpload(name, { target }) {
        const formData = new FormData();

        formData.set('file', target.files[0]);
        formData.set('fileName', name);

        handlerLoading.call(this, name, { error: null }).then(() => {
            axios
                .post(`${process.env.REACT_APP_API}/api/UploadParticipantFile`, formData, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        requestSuccess(res);

                        const { result } = res.data;

                        if (result === 'OK') {
                            checkAuth().then(() => {
                                this.initFields();

                                handlerLoading.call(this, null);
                            });
                        } else {
                            handlerLoading.call(this, null);
                        }
                    },
                    (err) => {
                        handlerLoading.call(this, null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ error: errorText });
                        } catch (error) {
                            this.setState({
                                error: 'Ошибка сервера',
                            });
                        }
                    },
                );
        });
    }

    sendAnket() {
        const { fields } = this.state;
        const { anketFields } = this.props;
        const body = {};

        console.log(anketFields);

        Object.keys(anketFields).forEach((key) => {
            if (anketFields[key].type !== 'photo') {
                body[key] = fields[key];
            }
        });

        body.agreement = !!fields.policy;

        handlerLoading.call(this, true, { error: null }).then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/SendParticipantInfo`,
                    {
                        ...body,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        requestSuccess(res);

                        const { result, JWT } = res.data;

                        if (result === 'OK') {
                            saveJWT(JWT);

                            checkAuth().then(() => {
                                handlerLoading.call(this, null);

                                this.setSuccess();
                            });
                        } else {
                            handlerLoading.call(this, null);
                        }
                    },
                    (err) => {
                        handlerLoading.call(this, null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ error: errorText });
                        } catch (error) {
                            this.setState({
                                error: 'Ошибка сервера',
                            });
                        }
                    },
                );
        });
    }

    initFields() {
        const { user, anketFields } = this.props;

        if (user && anketFields) {
            const fields = {};

            Object.keys(anketFields).forEach((key) => {
                fields[key] = anketFields[key].value;
            });

            if (user.status === 'PARTICIPANT') {
                fields.policy = true;
            }

            this.setState({ fields });
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.initFields();
    }

    render() {
        const {
            error,
            isSuccess = false,
            isCompleteSuccess = false,
            formHeight = 0,
            loadingKey,
            fields,
        } = this.state;
        const { anketFields } = this.props;

        return (
            <>
                <div ref={this.parent} className="faqForm _anket">
                    <div className="faqForm__inner">
                        <div className="faqForm__content">
                            <div className="faqForm__contentInner">
                                <div
                                    className={`form ${isCompleteSuccess ? '_success' : ''}`}
                                    style={isSuccess ? { height: `${formHeight}px` } : {}}
                                >
                                    <Animate className="form__success" isShow={isSuccess}>
                                        <div className="form__successContent">
                                            <span>Спасибо, {fields?.firstName}!</span>
                                            Информация отправлена, мы свяжемся с&nbsp;вами
                                            в&nbsp;ближайшее время.
                                        </div>
                                    </Animate>
                                    <div className="form__inner">
                                        <div className="form__fields">
                                            {anketFields &&
                                                Object.keys(anketFields).map((key) =>
                                                    this.renderField({
                                                        name: key,
                                                        field: anketFields[key],
                                                    }),
                                                )}
                                            {this.renderField({
                                                name: 'policy',
                                            })}
                                        </div>
                                        <div className="form__required">*Обязательные поля</div>
                                        <AnimateChange
                                            className="form__error _center"
                                            renderKey={error}
                                            paramsParent={{
                                                width: document.querySelector('.form__inner')
                                                    ?.offsetWidth,
                                            }}
                                            itemParams={['width']}
                                        >
                                            {error ? <div className="error">{error}</div> : null}
                                        </AnimateChange>
                                        <div className="form__button">
                                            <Button
                                                className="_purple"
                                                onClick={this.sendAnket}
                                                loader={loadingKey === true}
                                            >
                                                ОТПРАВИТЬ
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(AnketForm);

AnketForm.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
    anketFields: PropTypes.object,
};
