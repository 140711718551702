import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from '../components/Popup.jsx';
import ListAbsoluteMain from '../components/ListAbsoluteMain.jsx';
import Start from './cheques/Start.jsx';
import Error from './cheques/Error.jsx';
import Form from './cheques/Form.jsx';
import Scan from './cheques/Scan.jsx';
import Success from './cheques/Success.jsx';

import jsQR from '../plugins/QR';
import handlerLoading from '../functions/handlerLoading';


class Cheques extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: this.props.inPage ? 'form' : 'start',
            qrType: this.props.inPage ? 'typing' : undefined,
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.parseQr = this.parseQr.bind(this);
        this.uploadQr = this.uploadQr.bind(this);
        this.setBlock = this.setBlock.bind(this);
        this.setUpdateKey = this.setUpdateKey.bind(this);

        this.isPrivate = this.props.inPage ? undefined : true;

        this.parent = React.createRef();
    }

    allItems = ['start', 'scan', 'error', 'form', 'success'];

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ id: currentBlock }];
    }

    setBlock({ name, again, ...props }) {
        this.setState({
            currentBlock: name,
            ...props,
            ...(again ? { qrType: null } : {}),
        });
    }

    blocks = {
        start: {
            render() {
                return (
                    <>
                        <Start
                            loadingKey={this.state.loadingKey}
                            setBlock={this.setBlock}
                            uploadQr={this.uploadQr}
                        />
                    </>
                );
            },
        },
        scan: {
            render() {
                return (
                    <>
                        <Scan setBlock={this.setBlock} parseQr={this.parseQr} />
                    </>
                );
            },
        },
        error: {
            render() {
                const { qrType } = this.state;

                return (
                    <>
                        <Error
                            loadingKey={this.state.loadingKey}
                            setBlock={this.setBlock}
                            qrType={qrType}
                            uploadQr={this.uploadQr}
                        />
                    </>
                );
            },
        },
        form: {
            render() {
                const { qrType } = this.state;
                const { inPage } = this.props;

                return (
                    <>
                        <Form
                            scanData={this.state.scanData}
                            setBlock={this.setBlock}
                            setUpdateKey={this.setUpdateKey}
                            interfaceVal={inPage ? 'tg-bot' : null}
                            qrType={qrType}
                        />
                    </>
                );
            },
        },
        success: {
            render() {
                return (
                    <>
                        <Success setBlock={this.setBlock} />
                    </>
                );
            },
        },
    };

    renderBlock({ prop: id }) {
        const block = this.blocks[id];

        return <div className="popup__block">{block?.render.call(this)}</div>;
    }

    parseQr({ data }) {
        if (data) {
            const [time, sum, fn, fd, fp] = data.split('&').map((item) => item.split('=')[1]);

            const scanData = {
                month: time.slice(4, 6),
                day: time.slice(6, 8),
                hour: time.slice(9, 11),
                minute: time.slice(11, 13),
                sum,
                fn,
                fd,
                fp,
            };

            return scanData;
        }

        return null;
    }

    setUpdateKey() {
        this.setState({ renderUpdateKey: new Date().getTime() });
    }

    uploadQr({ target, qrType }) {
        const [file] = target.files;
        const fr = new FileReader();

        target.value = null;

        handlerLoading.call(this, 'file').then(() => {
            setTimeout(() => {
                fr.addEventListener(
                    'load',
                    () => {
                        const img = new Image();

                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const canvasContext = canvas.getContext('2d');
                            const width = img.width;
                            const height = img.height;

                            canvas.width = width;
                            canvas.height = height;
                            canvasContext.drawImage(img, 0, 0);

                            const qrCodeImageFormat = canvasContext.getImageData(
                                0,
                                0,
                                width,
                                height,
                            );

                            const qrDecoded = jsQR(
                                qrCodeImageFormat.data,
                                qrCodeImageFormat.width,
                                qrCodeImageFormat.height,
                            );

                            if (qrDecoded) {
                                const scanData = this.parseQr({ data: qrDecoded.data });

                                this.setBlock({ name: 'form', scanData, qrType });
                            } else {
                                this.setBlock({ name: 'error', qrType });
                            }

                            canvas.remove();

                            handlerLoading.call(this, null);
                        };

                        img.onerror = () => {
                            this.setBlock({ name: 'error', qrType });

                            handlerLoading.call(this, null);
                        };

                        img.src = fr.result;
                    },
                    false,
                );

                fr.readAsDataURL(file);
            }, 300);
        });
    }

    render() {
        const { currentBlock, renderUpdateKey } = this.state;
        const { inPage } = this.props;

        return (
            <>
                <Popup withoutClose={inPage}>
                    <ListAbsoluteMain
                        className="popup__blocks"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="popup__block"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentBlock}
                        allItems={this.allItems}
                        resizeParent={document.querySelector('.body')}
                        keyRender={renderUpdateKey}
                    />
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Cheques);

Cheques.propTypes = {
    device: PropTypes.string,
    inPage: PropTypes.bool,
};
