import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components } = this.props;
        const game = components?.game;

        return (
            <>
                <div ref={this.parent} className={`indexCity ${components ? '_ready' : ''}`}>
                    <div className="indexCity__inner">
                        <div className="indexCity__content">
                            <div className="indexCity__items">
                                {['icon1', 'icon2', 'icon3', 'icon4'].map((key) => (
                                    <div className="indexCity__item" key={key}>
                                        <div className="indexCity__itemHead">
                                            <img
                                                src={game?.[key]?.thumb}
                                                alt=""
                                                className="indexCity__itemImage"
                                            />
                                        </div>
                                        <div className="indexCity__itemTitle">
                                            {game?.[key]?.title}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <a
                                className="indexCity__button"
                                href={game?.button?.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="_purple">Играть</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
