import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import changePage from '../../functions/changePage';
import getNotPopupPage from '../../functions/getNotPopupPage';

class Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { setBlock } = this.props;

        return (
            <>
                <div className="popup__head">
                    <div className="popup__headTitle">регистрация чека</div>
                </div>
                <div className="popup__content">
                    <div className="popup__success">
                        <p className="popup__successDescription _title">Успешно!</p>
                        <p className="popup__successDescription">
                            Спасибо! Ваш чек отправлен на&nbsp;проверку.{' '}
                            <br className="_desktopMedia" />
                            Вы получите ответ на Email в течение 3-х&nbsp;дней.
                        </p>
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__buttons">
                        <div className="popup__button">
                            <Button
                                className="_emptyPurple"
                                onClick={() => {
                                    setBlock({ name: 'start', again: true });
                                }}
                            >
                                загрузить еще чек
                            </Button>
                        </div>
                        <div
                            className="popup__button"
                            onClick={() => {
                                changePage(getNotPopupPage());
                            }}
                        >
                            <Button className="_purple">закрыть окно</Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Success);

Success.propTypes = {
    device: PropTypes.string,
    setBlock: PropTypes.func,
    qrType: PropTypes.string,
    uploadQr: PropTypes.func,
    loadingKey: PropTypes.string,
};
