import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Page from '../components/Page.jsx';
import Header from './about/Header.jsx';

class About extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pageName = 'about';

    contentName = 'about';

    render() {
        const { components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _index">
                    <div className="page__section">
                        <Header components={components} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        indexAncor: state.indexAncor,
    };
}

export default connect(mapStateToProps)(About);

About.propTypes = {
    device: PropTypes.string,
    indexAncor: PropTypes.string,
};
