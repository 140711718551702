import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Page from '../components/Page.jsx';
import getHeaders from '../functions/getHeaders';
import requestSuccess from '../functions/requestSuccess';
import handlerPopup from '../functions/handlerPopup';
import Game from './profile/pages/Game.jsx';

class Profile extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPrivateContent = this.getPrivateContent.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'profile';

    contentName = 'profile';

    getPrivateContent(e) {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState(
                        { privateContent: res.data.data, updatedKey: new Date().getTime() },
                        () => {
                            if (
                                this.state.privateContent.codes?.length > 0 &&
                                !localStorage.getItem('showReferalPopup') &&
                                e === true
                            ) {
                                handlerPopup({ name: 'referalPopup', isShow: true });

                                localStorage.setItem('showReferalPopup', true);
                            }
                        },
                    );
                },
                () => {},
            );
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPrivateContent(true);

        document.addEventListener('updateProfile', this.getPrivateContent);
    }

    componentWillUnmount() {
        document.removeEventListener('updateProfile', this.getPrivateContent);
    }

    render() {
        const { privateContent, components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _game _inner">
                    <div className="page__section">
                        <div className="page__sectionInner">
                            <Game
                                parent={this.parent}
                                components={components}
                                privateContent={privateContent}
                                setUpdateKey={this.setUpdateKey}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
};
