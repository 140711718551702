import React from 'react';
import PropTypes from 'prop-types';

import changePage from '../functions/changePage';
import { getCookie } from '../functions/handlerCookies';

class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthWait: true,
        };

        this.checkAuth = this.checkAuth.bind(this);
    }

    checkAuth() {
        const { isAuthWait } = this.state;
        const { isAuthProccessed, user } = this.props;

        if (!isAuthProccessed) {
            if (this.isPrivate === true) {
                if (!getCookie(process.env.REACT_APP_HASH)) {
                    changePage({ href: '' });
                } else {
                    if (user?.status === 'ANKET_REQUIRED') {
                        changePage({ href: 'anket' });
                    }

                    if (user?.status === 'EMAIL_CONFIRM_REQUIRED') {
                        changePage({ href: '' });
                    }

                    if (isAuthWait) {
                        this.setState({ isAuthWait: false });
                    }
                }
            }

            if (this.isPrivate === false) {
                if (getCookie(process.env.REACT_APP_HASH)) {
                    changePage({ href: 'profile' });
                } else if (isAuthWait) {
                    this.setState({ isAuthWait: false });
                }
            }
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    componentDidUpdate() {
        this.checkAuth();
    }
}

export default AuthPage;

AuthPage.propTypes = {
    isAuthProccessed: PropTypes.bool,
    user: PropTypes.object,
};
