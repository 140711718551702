import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Registration from '../views/Registration.jsx';

class EmailPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    render() {
        return (
            <>
                <Registration isEmail={true} />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(EmailPopup);

EmailPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
