const easeInOut = (timeFraction) =>
    timeFraction > 0.5 ? 4 * Math.pow(timeFraction - 1, 3) + 1 : 4 * Math.pow(timeFraction, 3);

const easeOut = (timeFraction) => 1 - Math.pow(1 - timeFraction, 3);

const setAnimate = ({ timing = easeOut, duration, draw, callback, getId }) => {
    const start = performance.now();
    let animateId;

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;

        if (timeFraction > 1) {
            timeFraction = 1;
        }

        const progress = timing(timeFraction);

        draw(progress);

        if (timeFraction < 1) {
            animateId = requestAnimationFrame(animate);

            if (typeof getId === 'function') {
                getId(animateId);
            }
        }

        if (timeFraction === 1) {
            if (callback) {
                callback();
            }
        }
    });
};

export default class SliderV2 {
    constructor(settings = {}) {
        const {
            slider,
            area,
            moveArea,
            itemClass,
            showEach = false,
            infinity = false,
            buttons,
            pagination,
            current,
            callback,
            loop,
        } = settings;

        this.slider = slider;
        this.area = area;
        this.moveArea = moveArea;
        this.itemClass = itemClass;
        this.showEach = !!showEach;
        this.infinity = !!infinity;
        this.loop = loop;
        this.buttons = buttons;
        this.pagination = pagination;
        this.currentKey = current || 0;

        if (typeof callback === 'function') {
            this.callback = callback;
        }

        this.start = this.start.bind(this);
        this.move = this.move.bind(this);
        this.end = this.end.bind(this);
        this.resize = this.resize.bind(this);
        this.handlerKeys = this.handlerKeys.bind(this);
        this.visibilityChange = this.visibilityChange.bind(this);

        this.init();
    }

    getItem(value, prop) {
        if (prop === 'key') {
            let offsetItem = Infinity;
            let resultItem;

            this.area
                .querySelectorAll(`.${this.itemClass}[data-key="${value}"]`)
                .forEach((item) => {
                    if (this.getOffsetItem(item) >= 0 && this.getOffsetItem(item) < offsetItem) {
                        offsetItem = this.getOffsetItem(item);
                        resultItem = item;
                    }
                });

            return resultItem;
        }

        return this.area.querySelector(`.${this.itemClass}[data-id="${value}"]`);
    }

    init(withHandler = true) {
        this.startPos = 0;
        this.movePos = 0;
        this.endPos = 0;
        this.isMove = false;
        this.allItemId = 0;

        const startItems = [];

        this.area.querySelectorAll(`.${this.itemClass}`).forEach((item, key) => {
            startItems.push(item.cloneNode(true));

            item.setAttribute('data-key', key);
            item.setAttribute('data-id', this.allItemId++);
        });

        this.startItems = startItems;
        this.itemsCount = startItems.length;
        this.moveAreaWidth = this.moveArea.offsetWidth;

        if (!this.area.querySelector(`.${this.itemClass}[data-key="${this.currentKey}"]`)) {
            this.currentKey = 0;
        }

        this.current = +this.area
            .querySelectorAll(`.${this.itemClass}`)
            [this.currentKey]?.getAttribute('data-id');

        this.setStartInfo();
        this.setCurrent();
        this.setPagination();
        this.handlerCurrentItems();
        this.handlerPagination();
        this.setInfinity();

        if (this.buttons) {
            ['prev', 'next'].forEach((dir) => {
                const button = this.buttons[dir];

                if (button) {
                    button.onclick = () => {
                        this.handlerButton({ dir });
                    };
                }
            });
        }

        if (this.callback) {
            this.callback({ type: 'init', current: this.current, currentKey: this.currentKey });
        }

        if (withHandler) {
            document.addEventListener('stateResize', this.resize);
            window.addEventListener('resize', this.resize);

            this.area.addEventListener('mousedown', this.start, { passive: false });
            document.addEventListener('mousemove', this.move, { passive: false });
            document.addEventListener('mouseup', this.end);
            document.addEventListener('keydown', this.handlerKeys);

            this.area.addEventListener('touchstart', this.start, { passive: false });
            document.addEventListener('touchmove', this.move, { passive: false });
            document.addEventListener('touchend', this.end);

            document.addEventListener('visibilityChange', this.visibilityChange);
        }

        this.setLoop();
    }

    setLoop() {
        if (this.loop) {
            clearInterval(this.loopIntervalId);

            this.loopIntervalId = setInterval(() => {
                this.handlerButton({ dir: 'next' });
            }, this.loop);
        }
    }

    clearLoop() {
        clearInterval(this.loopIntervalId);
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            this.clearLoop();
        } else {
            this.setLoop();
        }
    }

    maxItemWidth = 0;

    setStartInfo() {
        let maxItemWidth = 0;

        this.startItems.forEach((item) => {
            if (item.offsetWidth > maxItemWidth) {
                maxItemWidth = item.offsetWidth;
            }
        });

        this.maxItemWidth = maxItemWidth;

        let itemsGroups = [];

        if (this.showEach) {
            itemsGroups = this.startItems.map((item, key) => [key]);
        } else {
            let currentGroup = 0;
            let currentIndex = 0;
            let currentItemOffset = 0;
            let currentItem = Array.from(this.area.querySelectorAll(`.${this.itemClass}`))[0];

            while (currentItem) {
                currentItemOffset = this.getOffsetItem(currentItem) + currentItem.offsetWidth;

                currentGroup = Math.floor(currentItemOffset / this.area.offsetWidth);

                if (!itemsGroups[currentGroup]) {
                    itemsGroups[currentGroup] = [];
                }

                itemsGroups[currentGroup].push(currentIndex);

                currentIndex += 1;
                currentItem = currentItem.nextElementSibling;
            }
        }

        this.itemsGroups = itemsGroups;

        if (!this.infinity) {
            let lastItemKey;

            const lastItem = this.area.querySelector(
                `.${this.itemClass}[data-key="${this.itemsCount - 1}"]`,
            );

            this.startItems.forEach((item, key) => {
                const itemOffset = this.getOffsetItem(item);
                const lastItemOffset = this.getOffsetItem(lastItem) + lastItem.offsetWidth;

                if (lastItemOffset - itemOffset < this.area.offsetWidth && !lastItemKey) {
                    lastItemKey = key;
                }
            });

            if (this.showEach) {
                lastItemKey = this.itemsCount - 1;
            }

            this.lastItemKey = lastItemKey;

            this.leftMaxOffset = 0;

            const rightMaxItem = this.startItems[this.lastItemKey];

            this.rightMaxOffset = this.getOffsetItem(rightMaxItem);
        } else {
            this.lastItemKey = Infinity;
        }
    }

    setCurrent() {
        this.moveToCurrentItem({ current: this.current, force: true });
    }

    handlerButton({ dir }) {
        if (!this.buttonProcess) {
            let current = null;

            const currentItem = this.getItem(this.current);

            if (this.showEach) {
                const nextItem =
                    dir === 'next'
                        ? currentItem.nextElementSibling
                        : currentItem.previousElementSibling;

                if (nextItem) {
                    current = +nextItem.getAttribute('data-id');
                }
            } else {
                let resultNextItem;
                let nextItem = currentItem;
                const resultWidth = this.area.offsetWidth * (dir === 'next' ? 1 : -1);
                let currentWidth = 0;

                while (
                    nextItem &&
                    (dir === 'next'
                        ? currentWidth < resultWidth &&
                          +nextItem.getAttribute('data-key') < this.lastItemKey
                        : currentWidth > resultWidth)
                ) {
                    nextItem =
                        dir === 'next'
                            ? nextItem.nextElementSibling
                            : nextItem.previousElementSibling;

                    if (nextItem) {
                        resultNextItem = nextItem;

                        currentWidth =
                            this.getOffsetItem(nextItem) +
                            nextItem.offsetWidth * (dir === 'next' ? 1 : -1);
                    }
                }

                if (resultNextItem) {
                    current = +resultNextItem.getAttribute('data-id');
                }
            }

            if (current !== null) {
                this.buttonProcess = true;

                if (this.animateId) {
                    cancelAnimationFrame(this.animateId);

                    this.endPos = Math.round(this.movePos);
                }

                this.moveToCurrentItem({
                    current,
                    inertValue: 0,
                    callback: () => {
                        this.buttonProcess = false;
                    },
                });

                this.setLoop();
            }
        }
    }

    handlerCurrentItems() {
        if (this.showEach) {
            const currentItem = this.getItem(this.current);

            this.area.querySelectorAll(`.${this.itemClass}`).forEach((item) => {
                item.classList.remove('_current');
            });

            if (currentItem) {
                currentItem.classList.add('_current');
            }
        } else {
            this.area.querySelectorAll(`.${this.itemClass}`).forEach((item) => {
                const offsetItem = this.getOffsetItem(item);

                if (offsetItem >= 0 && offsetItem + item.offsetWidth < this.area.offsetWidth) {
                    item.classList.add('_current');
                } else {
                    item.classList.remove('_current');
                }
            });
        }
    }

    handlerPagination() {
        if (this.paginationInit) {
            const { parent, itemClass } = this.pagination;
            const currentItem = this.getItem(this.current);
            const currentItemKey = +currentItem?.getAttribute('data-key');

            const currentKey = this.itemsGroups.findIndex(
                (group) => group.indexOf(currentItemKey) !== -1,
            );

            parent.querySelectorAll(`.${itemClass}`).forEach((item, key) => {
                item.classList.remove('_current');

                if (key === currentKey) {
                    item.classList.add('_current');
                }
            });
        }
    }

    setPagination() {
        if (this.pagination) {
            const { parent, itemClass } = this.pagination;

            if (typeof parent === 'object' && typeof itemClass === 'string') {
                this.itemsGroups.forEach((group, key) => {
                    const pagItem = document.createElement('div');

                    pagItem.setAttribute('data-key', key);

                    pagItem.classList.add(itemClass);

                    parent.appendChild(pagItem);
                });

                this.paginationInit = true;
            }
        }
    }

    resizeTimerId;

    handlerResize() {
        this.destroyNodes();
        this.init(false);
    }

    resize() {
        if (this.resizeTimerId) {
            clearTimeout(this.resizeTimerId);
        }

        this.resizeTimerId = setTimeout(() => {
            this.handlerResize();
        }, 10);
    }

    getNeedItems({ dir }) {
        const startItem =
            this.moveArea.children[dir === 'prev' ? 0 : this.moveArea.children.length - 1];
        const startItemsIndexes = {};

        this.startItems.forEach((item, key) => {
            startItemsIndexes[key] = item.cloneNode(true);
        });

        const currentItem = startItem;
        const resultItems = [];

        let currentIndex = +currentItem.getAttribute('data-key');
        const inertMaxWidth = this.inertMax * this.inertStep;
        const resultWidth = inertMaxWidth + this.area.offsetWidth;
        let currentWidth = 0;

        while (currentWidth < resultWidth) {
            if (dir === 'prev') {
                if (currentIndex === 0) {
                    currentIndex = this.startItems.length;
                }

                currentIndex -= 1;
            }

            if (dir === 'next') {
                if (currentIndex === this.startItems.length - 1) {
                    currentIndex = -1;
                }

                currentIndex += 1;
            }

            currentWidth += this.area.querySelector(
                `.${this.itemClass}[data-key="${currentIndex}"]`,
            ).offsetWidth;

            resultItems.push({ item: startItemsIndexes[currentIndex], key: currentIndex });
        }

        return resultItems;
    }

    setInfinity() {
        if (this.infinity) {
            const currentItem = this.moveArea.querySelector(
                `.${this.itemClass}[data-id="${this.current}"]`,
            );

            if (currentItem) {
                ['prev', 'next'].forEach((dir) => {
                    const items = this.getNeedItems({ dir });

                    if (items.length) {
                        items.forEach(({ item, key }) => {
                            const cloneItem = item.cloneNode(true);

                            cloneItem.setAttribute('data-key', key);
                            cloneItem.setAttribute('data-id', this.allItemId++);

                            this.moveArea[dir === 'prev' ? 'prepend' : 'append'](cloneItem);
                        });

                        if (dir === 'prev') {
                            const currentItemOffset = this.getOffsetItem(currentItem);

                            this.endPos -= currentItemOffset;

                            this.setMove(this.endPos);
                        }
                    }
                });

                const deletedItems = [];

                const inertMaxWidth = this.inertMax * this.inertStep;
                const resultWidth =
                    inertMaxWidth + this.area.offsetWidth * 2 + this.maxItemWidth / 2;

                this.area.querySelectorAll(`.${this.itemClass}`).forEach((item) => {
                    const itemOffset = this.getOffsetItem(item);

                    if (Math.abs(itemOffset) > resultWidth) {
                        deletedItems.push(item.getAttribute('data-id'));
                    }
                });

                deletedItems.forEach((id) => {
                    const item = this.area.querySelector(`.${this.itemClass}[data-id="${id}"]`);

                    item.remove();
                });

                const currentItemOffset = this.getOffsetItem(currentItem);

                this.endPos -= currentItemOffset;

                this.setMove(this.endPos);
            }
        }
    }

    startCheckX = 0;

    moveCheckX = 0;

    startCheckY = 0;

    moveCheckY = 0;

    accessScroll = false;

    disabledScroll = false;

    start(e) {
        if (!this.isMove) {
            this.accessScroll = false;
            this.disabledScroll = false;

            if (this.animateId) {
                cancelAnimationFrame(this.animateId);

                this.animateId = null;

                this.endPos = this.movePos;
            }

            this.isMove = true;

            const startValue = (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageX;

            this.startPos = startValue;

            this.startCheckX = (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageX;
            this.startCheckY = (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageY;

            this.move(e);

            this.callback({
                type: 'startDrag',
            });

            clearInterval(this.loopIntervalId);
        }
    }

    moveTime = null;

    moveTimeStart = null;

    moveTimerId = null;

    endTime = null;

    setMove(value) {
        this.moveArea.style.transform = `translate3d(${value}px,0,0)`;
    }

    move(e) {
        if (this.isMove) {
            clearTimeout(this.moveTimerId);

            this.moveCheckX = Math.abs(
                this.startCheckX - (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageX,
            );
            this.moveCheckY = Math.abs(
                this.startCheckY - (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageY,
            );

            if (!e.changedTouches) {
                this.accessScroll = true;
            } else if (this.moveCheckY > 6 && !this.accessScroll) {
                this.disabledScroll = true;
            } else if (this.moveCheckX > 5 && !this.disabledScroll) {
                this.accessScroll = true;
            }

            if (this.accessScroll) {
                e.preventDefault();

                const moveValue = (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageX;

                if (!this.moveTime) {
                    this.moveTime = new Date().getTime();
                    this.moveTimeStart = moveValue;
                }

                this.moveTimerId = setTimeout(() => {
                    this.moveTime = null;
                    this.moveTimeStart = null;
                }, 500);

                this.direction = this.startPos - moveValue < 0 ? -1 : 1;

                let movePos =
                    -(this.startPos - moveValue) +
                    this.endPos +
                    (e.changedTouches ? 5 * this.direction : 0);

                if (!this.infinity) {
                    if (movePos > this.leftMaxOffset) {
                        const delta = movePos - this.leftMaxOffset;
                        const scale = 1 + delta / 200;

                        movePos = this.leftMaxOffset + delta / scale;
                    }

                    if (movePos < -this.rightMaxOffset) {
                        const delta = movePos + this.rightMaxOffset;
                        const scale = 1 + Math.abs(delta) / 200;

                        movePos = -this.rightMaxOffset + delta / scale;
                    }
                }

                this.movePos = movePos;

                this.setMove(this.movePos);
            }
        }
    }

    end(e) {
        if (this.isMove) {
            clearTimeout(this.moveTimerId);

            const endPos = (e.changedTouches?.[0] ? e.changedTouches[0] : e).pageX;

            this.endTime = new Date().getTime();

            if (this.accessScroll) {
                this.endPos = this.movePos;
            }

            this.startPos = 0;
            this.movePos = 0;

            let inertValue = 0;

            if (this.moveTime) {
                inertValue = Math.abs(
                    (this.moveTimeStart - endPos) / (this.endTime - this.moveTime),
                );
            }

            if (inertValue > this.inertMax) {
                inertValue = this.inertMax;
            }

            // inertValue = 8;

            this.setEndCurrent(inertValue);

            this.isMove = false;
            this.endTime = null;
            this.moveTime = null;
            this.moveTimeStart = null;

            if (this.accessScroll) {
                this.callback({
                    type: 'endDrag',
                });
            }

            this.setLoop();
        }
    }

    getOffsetItem(item) {
        return item.getBoundingClientRect().x - this.area.getBoundingClientRect().x;
    }

    inertMax = 8;

    inertStep = 200;

    setEndCurrent(inertValue = 0) {
        const end = {
            offset: Infinity,
            current: null,
        };

        this.area.querySelectorAll(`.${this.itemClass}`).forEach((item, key) => {
            const itemOffset = this.getOffsetItem(item);
            const id = +item.getAttribute('data-id');
            let calcItemOffset = itemOffset;

            if (inertValue > 2) {
                calcItemOffset -= inertValue * this.inertStep * this.direction;
            }

            if (
                Math.abs(calcItemOffset) < Math.abs(end.offset) &&
                (this.infinity || key <= this.lastItemKey)
            ) {
                end.offset = calcItemOffset;
                end.current = id;
            }
        });

        end.inertValue = inertValue;

        this.moveToCurrentItem(end);
    }

    moveToCurrentItem({ current, inertValue, force, callback }) {
        const startOffset = this.endPos;
        const currentItem = this.area.querySelector(`.${this.itemClass}[data-id="${current}"]`);

        if (currentItem) {
            const offset = this.getOffsetItem(currentItem);

            this.current = current;
            this.currentKey = +currentItem.getAttribute('data-key');

            this.handlerPagination();

            if (offset !== 0) {
                this.callback({
                    type: 'startMove',
                    current: this.current,
                    currentKey: this.currentKey,
                });

                setAnimate({
                    timing: inertValue > 2 ? easeOut : easeInOut,
                    duration: force ? 0 : 500,
                    draw: (progress) => {
                        this.movePos = startOffset - progress * offset;

                        this.setMove(this.movePos);
                    },
                    callback: () => {
                        this.endPos = Math.round(this.movePos);
                        this.startPos = 0;
                        this.movePos = 0;

                        this.animateId = null;

                        this.setInfinity();
                        this.handlerCurrentItems();

                        if (typeof callback === 'function') {
                            callback();
                        }

                        if (this.callback) {
                            this.callback({
                                type: 'move',
                                current: this.current,
                                currentKey: this.currentKey,
                            });
                        }

                        // console.log(this.current);
                    },
                    getId: (id) => {
                        this.animateId = id;
                    },
                });
            }
        }
    }

    handlerKeys(e) {
        if ([37, 39].includes(e.which)) {
            e.preventDefault();

            this.handlerButton({ dir: e.which === 37 ? 'prev' : 'next' });
        }
    }

    destroyNodes() {
        if (this.paginationInit) {
            const { parent } = this.pagination;

            parent.innerHTML = '';
        }

        this.moveArea.innerHTML = '';
        this.moveArea.style = null;

        this.startItems.forEach((startItem) => {
            this.moveArea.appendChild(startItem.cloneNode(true));
        });
    }

    destroy() {
        this.destroyNodes();

        this.clearLoop();

        if (this.buttons) {
            ['prev', 'next'].forEach((key) => {
                const button = this.buttons[key];

                if (button) {
                    button.onclick = null;
                }
            });
        }

        document.removeEventListener('stateResize', this.resize);
        window.removeEventListener('resize', this.resize);

        this.area.removeEventListener('mousedown', this.start, { passive: false });
        document.removeEventListener('mousemove', this.move, { passive: false });
        document.removeEventListener('mouseup', this.end);
        document.removeEventListener('keydown', this.handlerKeys);

        this.area.removeEventListener('touchstart', this.start, { passive: false });
        document.removeEventListener('touchmove', this.move, { passive: false });
        document.removeEventListener('touchend', this.end);

        document.removeEventListener('visibilityChange', this.visibilityChange);
    }
}
