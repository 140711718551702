import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import Field from './Field.jsx';
import Checkbox from './Checkbox.jsx';

import getHeaders from '../functions/getHeaders';
import saveJWT from '../functions/saveJWT';

class GameEnergyPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.parent = React.createRef();
    }

    async send() {
        const { isConfirm, fields } = this.state;
        const { gameEnergyPopup } = this.props;
        const { login } = gameEnergyPopup;

        if (!isConfirm) {
            this.setState({ error: 'Необходимо подтвердить перевод' });

            return;
        }

        if (!fields.energy) {
            this.setState({ error: 'Укажите количество баллов' });

            return;
        }

        await this.handlerLoading('send', { error: null });

        try {
            const response = await axios.post(
                `/api/AddGameEnergy`,
                {
                    login,
                    energy: fields?.energy,
                },
                {
                    headers: getHeaders(),
                },
            );
            const { result, JWT } = response.data;

            saveJWT(JWT);

            if (result === 'OK') {
                handlerPopup({ name: 'gameEnergyPopup', isShow: false });

                document.dispatchEvent(new CustomEvent('updateProfile'));
            }
        } catch (error) {
            this.setState({ error: error?.response?.data?.errorText });
        }

        await this.handlerLoading(null);
    }

    render() {
        const { fields, error, loadingKey, isConfirm } = this.state;
        const { gameEnergyPopup } = this.props;
        const { login } = gameEnergyPopup;

        return (
            <Popup name="gameEnergyPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Перевод баллов в Игру</div>
                    <div className="popup__headDescription">
                        Введите количество баллов. <br />
                        которое хотите перевести участнику:
                    </div>
                    <div className="popup__headAccount">{login}</div>
                </div>
                <div className="popup__content">
                    <div className="popup__fields">
                        <div className="popup__field">
                            <Field
                                className="_medium _center"
                                name="energy"
                                value={fields?.energy}
                                onChange={this.handlerField}
                            />
                        </div>
                        <div className="popup__field _checkbox">
                            <Checkbox
                                checked={isConfirm}
                                onChange={() => {
                                    this.setState({ isConfirm: !isConfirm });
                                }}
                            >
                                Подтверждаю перевод
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    {this.renderError({ error })}
                    <div className="popup__buttons">
                        <div className="popup__button _auto">
                            <Button
                                className="_purple"
                                onClick={this.send.bind(this)}
                                loader={loadingKey === 'send'}
                            >
                                ПЕРЕВЕСТИ
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        gameEnergyPopup: state.gameEnergyPopup,
    };
}

export default connect(mapStateToProps)(GameEnergyPopup);

GameEnergyPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    gameEnergyPopup: PropTypes.object,
};
