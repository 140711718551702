import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from '../../components/Link.jsx';
import pages from '../../redux/pages';
import getPageLink from '../../functions/getPageLink';
import Pages from '../../components/Pages.jsx';
import scrollToPosition from '../../functions/scrollToPosition';

import Prizes from './pages/Prizes.jsx';
import Cheques from './pages/Cheques.jsx';
import Marks from './pages/Marks.jsx';
import Game from './pages/Game.jsx';

class ProfileContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setUpdateKey = this.setUpdateKey.bind(this);
        this.scrollToSounds = this.scrollToSounds.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'profile-marks': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Marks
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                            components={components}
                        />
                    </>
                );
            },
        },
        'profile-cheques': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Cheques
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                            components={components}
                        />
                    </>
                );
            },
        },
        'profile-prizes': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Prizes
                            parent={this.parent}
                            components={components}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                        />
                    </>
                );
            },
        },
        'profile-game': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Game
                            parent={this.parent}
                            components={components}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                        />
                    </>
                );
            },
        },
    };

    setUpdateKey() {
        this.setState({ pagesUpdateKey: new Date().getTime() });
    }

    scrollToSounds({ detail: { duration = 300 } }) {
        const topBar = document.querySelector('.topBar');
        let offset = 0;

        if (topBar) {
            offset = topBar.offsetHeight + 50;
        }

        scrollToPosition({
            position: 'top',
            parent: this.parent.current.closest('.body__page'),
            classNameElem: '.profileContent__pages',
            offset,
            duration,
        });
    }

    checkInit() {
        const { components, privateContent } = this.props;

        if (components && privateContent && !this.isInit) {
            this.isInit = true;

            this.setUpdateKey();
        }
    }

    componentDidMount() {
        this.checkInit();

        document.addEventListener('scrollToSounds', this.scrollToSounds);
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        document.removeEventListener('scrollToSounds', this.scrollToSounds);
    }

    render() {
        const { pagesUpdateKey } = this.state;
        const { storePages, updatedKey } = this.props;
        const profilePages = pages.filter(
            (page) => page.parentName === 'profile' && page.level === 1,
        );

        return (
            <>
                <div ref={this.parent} className="profileContent">
                    <div className="profileContent__inner">
                        <div className="profileContent__content">
                            <div className="profileContent__tabs">
                                {profilePages.map((page, key) => {
                                    const { content } = page;

                                    return (
                                        <div className="profileContent__tab" key={key}>
                                            <Link
                                                className="profileContent__tabInner"
                                                pageName={page.name}
                                                href={getPageLink({ name: page.name, storePages })}
                                            >
                                                {content}
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                            <Pages
                                className="profileContent__pages"
                                classNamePage="profileContent__page"
                                filter={(page) => page.parentName === 'profile' && page.level === 1}
                                pages={this.pages}
                                context={this}
                                isFull={false}
                                isNotParams={false}
                                keyRender={`${pagesUpdateKey}${updatedKey}`}
                                // name="test"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(ProfileContent);

ProfileContent.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    storePages: PropTypes.object,
    components: PropTypes.object,
    updatedKey: PropTypes.any,
};
