import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../components/Button.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';
import handlerPopup from '../../../functions/handlerPopup';

class ProfileContentGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components, privateContent } = this.props;
        const game = components?.game?.info;
        const accounts = privateContent?.gameAccounts || [];

        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">участие в Игре</div>
                </div>
                <div className="profileContent__pageContent">
                    <div
                        className={`profileContent__game ${
                            privateContent && components ? '_ready' : ''
                        }`}
                    >
                        <img
                            src={require('../../../media/game-logo.png')}
                            alt=""
                            className="profileContent__gameLogo"
                        />
                        <div className="profileContent__gameHead">
                            <div
                                className="profileContent__gameTitle"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(game?.title?.title),
                                }}
                            ></div>
                            <p
                                className="profileContent__gameDescription"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(game?.title?.description),
                                }}
                            ></p>
                            <div className="profileContent__gameButton">
                                <Button
                                    className="_pink"
                                    onClick={() => {
                                        handlerPopup({ name: 'gameUserPopup', isShow: true });
                                    }}
                                >
                                    {game?.button?.title}
                                </Button>
                            </div>
                        </div>

                        {components && accounts.length > 0 && (
                            <div className="profileContent__gameTable">
                                <div className="profileContent__gameTableRow _head">
                                    <div className="profileContent__gameTableCol _login">Логин</div>
                                    <div className="profileContent__gameTableCol _count">
                                        Уровень энергии
                                    </div>
                                    <div className="profileContent__gameTableCol _action">
                                        Добавить энергию
                                    </div>
                                </div>
                                {accounts.map((item, key) => {
                                    const { login, energy } = item;

                                    return (
                                        <div className="profileContent__gameTableRow" key={key}>
                                            <div className="profileContent__gameTableCol _login">
                                                {login}
                                            </div>
                                            <div className="profileContent__gameTableCol _count">
                                                <div className="profileContent__gameTableCount">
                                                    {energy || '–'}
                                                </div>
                                            </div>
                                            <div className="profileContent__gameTableCol _action">
                                                <img
                                                    src={
                                                        require('../../../media/action-icon.svg')
                                                            .default
                                                    }
                                                    alt=""
                                                    className="profileContent__gameTableAction"
                                                    onClick={() => {
                                                        handlerPopup({
                                                            name: 'gameEnergyPopup',
                                                            isShow: true,
                                                            login,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentGame);

ProfileContentGame.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
    components: PropTypes.object,
};
