import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';

import getHeaders from '../functions/getHeaders';
import saveJWT from '../functions/saveJWT';
import getEndText from '../functions/getEndText';

class RufflePopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.parent = React.createRef();
    }

    async send() {
        const { rufflePopup } = this.props;
        const { raffle } = rufflePopup;

        await this.handlerLoading('send', { error: null });

        try {
            const response = await axios.post(
                `/api/AddUserRaffle`,
                {
                    raffle,
                },
                {
                    headers: getHeaders(),
                },
            );
            const { result, JWT } = response.data;

            saveJWT(JWT);

            if (result === 'OK') {
                handlerPopup({ name: 'rufflePopup', isShow: false });

                document.dispatchEvent(new CustomEvent('updateProfile'));
            }
        } catch (error) {
            this.setState({ error: error?.response?.data?.errorText });
        }

        await this.handlerLoading(null);
    }

    render() {
        const { error, loadingKey } = this.state;
        const { rufflePopup } = this.props;
        const { price } = rufflePopup;

        return (
            <Popup name="rufflePopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Подтверждение</div>
                </div>
                <div className="popup__content">
                    <div className="popup__ruffle">
                        <div className="popup__ruffleText">
                            Подтвердите, что участвуете в розыгрыше, с&nbsp;вас будет списано:
                        </div>
                        <div className="popup__ruffleResult">
                            <span>{price}</span> {getEndText(price, ['балл', 'балла', 'баллов'])}
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    {this.renderError({ error })}
                    <div className="popup__buttons">
                        <div className="popup__button">
                            <Button
                                className="_emptyPurple"
                                onClick={() => {
                                    handlerPopup({ name: 'rufflePopup', isShow: false });
                                }}
                            >
                                Позже...
                            </Button>
                        </div>
                        <div className="popup__button">
                            <Button
                                className="_purple"
                                onClick={this.send.bind(this)}
                                loader={loadingKey === 'send'}
                            >
                                ПОДТВЕРДИТЬ
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        rufflePopup: state.rufflePopup,
    };
}

export default connect(mapStateToProps)(RufflePopup);

RufflePopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    rufflePopup: PropTypes.object,
};
