import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Button from './Button.jsx';
import handlerPopup from '../functions/handlerPopup';
import setSpacesInText from '../functions/setSpacesInText';

class CuponPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.close = this.close.bind(this);

        this.parent = React.createRef();
    }

    close() {
        handlerPopup({
            name: 'cuponPopup',
            isShow: false,
            title: null,
            image: null,
            description: null,
            info: null,
        });
    }

    componentDidMount() {
        const { cuponPopup } = this.props;

        this.setState({ ...cuponPopup });
    }

    componentWillUnmount() {
        this.close();
    }

    render() {
        const { title, image, description, info } = this.state;

        return (
            <>
                <Popup name="cuponPopup">
                    <div className="popup__head">
                        <div
                            className="popup__headTitle _block"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                        ></div>
                    </div>
                    <div className="popup__content">
                        <div className="popup__cupon">
                            <img src={image} alt="" className="popup__cuponCode" />
                            <p
                                className="popup__cuponDescription"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                            ></p>
                            <div
                                className="popup__cuponInfo"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(info) }}
                            ></div>
                        </div>
                    </div>
                    <div className="popup__foot">
                        <div className="popup__button _auto">
                            <Button className="_purple _dark" onClick={this.close}>
                                закрыть
                            </Button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        cuponPopup: state.cuponPopup,
    };
}

export default connect(mapStateToProps)(CuponPopup);

CuponPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    cuponPopup: PropTypes.object,
};
