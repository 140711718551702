import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import Field from './Field.jsx';

import getHeaders from '../functions/getHeaders';
import saveJWT from '../functions/saveJWT';

class GameUserPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.parent = React.createRef();
    }

    async send() {
        const { fields } = this.state;

        await this.handlerLoading('send', { error: null });

        try {
            const response = await axios.post(
                `/api/AddGameAccount`,
                {
                    login: fields?.username,
                    password: fields?.userpassword,
                },
                {
                    headers: getHeaders(),
                },
            );
            const { result, JWT } = response.data;

            saveJWT(JWT);

            if (result === 'OK') {
                handlerPopup({ name: 'gameUserPopup', isShow: false });

                document.dispatchEvent(new CustomEvent('updateProfile'));
            }
        } catch (error) {
            this.setState({ error: error?.response?.data?.errorText });
        }

        await this.handlerLoading(null);
    }

    render() {
        const { fields, error, loadingKey } = this.state;

        return (
            <Popup name="gameUserPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Привязка аккаунта к&nbsp;Игре</div>
                    <div className="popup__headDescription">Введите логин и пароль от Игры</div>
                    <div className="popup__headDescription _purple">
                        Если пользователя с таким логином не&nbsp;существует,{' '}
                        <br className="_desktopMedia" />
                        он&nbsp;будет создан
                    </div>
                </div>
                <div className="popup__content">
                    <div className="popup__fields">
                        <div className="popup__field">
                            <Field
                                className="_medium"
                                support="Логин"
                                name="username"
                                value={fields?.username}
                                onChange={this.handlerField}
                            />
                        </div>
                        <div className="popup__field">
                            <Field
                                className="_medium"
                                support="Пароль"
                                name="userpassword"
                                value={fields?.userpassword}
                                onChange={this.handlerField}
                            ></Field>
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    {this.renderError({ error })}
                    <div className="popup__buttons">
                        <div className="popup__button _auto">
                            <Button
                                className="_purple"
                                onClick={this.send.bind(this)}
                                loader={loadingKey === 'send'}
                            >
                                ПРИВЯЗАТЬ
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(GameUserPopup);

GameUserPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
