import { dispatcher, store } from '../redux/redux';

export default function setMainContent({ components, extra }) {
    const { mainContent = {} } = store.getState();
    const data = {};

    if (components?.header) {
        data.header = components.header;
    }

    if (components?.footer) {
        data.footer = components.footer;
    }

    if (extra?.socAuthUrls) {
        data.socAuthUrls = extra.socAuthUrls;
    }

    dispatcher({ type: 'mainContent', data: { ...mainContent, ...data } });
}
