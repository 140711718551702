import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import handlerLoading from '../../functions/handlerLoading';
import getEndText from '../../functions/getEndText';

import logout from '../../functions/logout';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';
import handlerPopup from '../../functions/handlerPopup';
import changePage from '../../functions/changePage';
import Link from '../../components/Link.jsx';

class ProfileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.logout = this.logout.bind(this);

        this.parent = React.createRef();
    }

    logout() {
        handlerLoading.call(this, 'logout').then(() => {
            logout().then(
                () => {
                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { privateContent, user, components } = this.props;
        const names = [];
        const balance = privateContent?.balance || 0;

        if (user) {
            if (user.personal.firstName) {
                names.push(user.personal.firstName);
            }

            if (user.personal.lastName) {
                names.push(user.personal.lastName);
            }
        }

        return (
            <>
                <div
                    ref={this.parent}
                    className={`profileHeader ${privateContent ? '_ready' : ''}`}
                >
                    <div className="profileHeader__inner">
                        <div className="profileHeader__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__title _INNERTITLE">
                                    Личный кабинет
                                </div>
                            </div>
                        </div>
                        <div className="profileHeader__content">
                            <div className="profileHeader__contentInner">
                                <div className="profileHeader__contentName">
                                    {names.join(' ')}
                                    <div className="profileHeader__contentId">
                                        ID {user?.userId}
                                    </div>
                                </div>
                                <Link
                                    className="profileHeader__contentLink"
                                    pageName={
                                        user?.status === 'EXTRA_ANKET_REQUIRED'
                                            ? 'fullAnket'
                                            : 'anket'
                                    }
                                >
                                    изменить данные
                                </Link>
                                <div className="profileHeader__contentCount">
                                    <div className="profileHeader__contentCountTitle">
                                        У ВАС{' '}
                                        <div className="profileHeader__contentCountTitleItem">
                                            <span>{balance}</span>
                                        </div>{' '}
                                        {getEndText(balance, ['балл', 'балла', 'баллов'])}
                                    </div>
                                    <div
                                        className="profileHeader__contentLink"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'marksPopup',
                                                isShow: true,
                                                items: privateContent?.transactions,
                                            });
                                        }}
                                    >
                                        история
                                    </div>
                                </div>
                                <div className="profileHeader__contentButtons">
                                    <div className="profileHeader__contentButton">
                                        <Button
                                            className="_pink"
                                            onClick={() => {
                                                changePage({ href: 'cheques' });
                                            }}
                                        >
                                            {components?.buttons?.button2?.title || '–'}
                                        </Button>
                                    </div>
                                    <a
                                        className="profileHeader__contentButton _game"
                                        href={components?.buttons?.button1?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button className="_purple">
                                            {components?.buttons?.button1?.title || '–'}
                                        </Button>
                                    </a>
                                </div>
                                <div
                                    className="profileHeader__contentLogout"
                                    onClick={this.logout.bind(this)}
                                >
                                    <Animate
                                        className="profileHeader__contentLogoutLoader"
                                        isShow={loadingKey === 'logout'}
                                    >
                                        <Loader />
                                    </Animate>
                                    Выход
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileHeader);

ProfileHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    components: PropTypes.object,
};
