import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FaqHeader from './faq/Header.jsx';
import FaqForm from './faq/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import scrollToPosition from '../functions/scrollToPosition';
import { dispatcher } from '../redux/redux';

class Faq extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.scrollToForm = this.scrollToForm.bind(this);

        this.parent = React.createRef();
    }

    pageName = 'faq';

    contentName = 'faq';

    scrollToForm(isFinal) {
        const { ancorToFaqForm } = this.props;

        if (ancorToFaqForm) {
            const topBar = document.querySelector('.topBar');
            let offset = 0;

            if (topBar) {
                offset = topBar.offsetHeight;
            }

            scrollToPosition({
                position: 'top',
                parent: this.parent.current.closest('.body__page'),
                classNameElem: '#faqForm',
                offset,
                duration: isFinal ? 300 : 0,
            });

            if (isFinal) {
                dispatcher({ type: 'ancorToFaqForm', data: false });
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.scrollToForm();
    }

    render() {
        const { components } = this.state;
        const faq = components?.faq;
        
        return (
            <>
                <div ref={this.parent} className="page _faq _inner">
                    <div className="page__section">
                        <FaqHeader faq={faq} scrollToForm={this.scrollToForm} />
                    </div>
                    <div className="page__section">
                        <FaqForm components={components} />
                    </div>
                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        ancorToFaqForm: state.ancorToFaqForm,
    };
}

export default connect(mapStateToProps)(Faq);

Faq.propTypes = {
    device: PropTypes.string,
    ancorToFaqForm: PropTypes.bool,
};
