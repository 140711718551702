import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Link from './Link.jsx';

import getNav from '../functions/getNav';
import { dispatcher } from '../redux/redux';
import changePage from '../functions/changePage';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    keys = {
        index: 'main',
        rules: 'rules',
        about: 'about',
        winners: 'winners',
        faq: 'faq',
    };

    checkReady() {
        const { mainContent } = this.props;

        if (mainContent?.header && !this.isReady) {
            this.isReady = true;

            this.setState({ isInit: true });
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { mobileMenuShow, mainContent, isActive, isInner, user } = this.props;

        return (
            <div className={`body__topBar ${isActive ? '_active' : ''}`}>
                <div
                    className={`topBar ${isActive || isInner ? '_active' : ''} ${
                        !isActive && isInner ? '_inner' : ''
                    }`}
                >
                    <div className="topBar__inner">
                        <div className="topBar__block _logo">
                            <div
                                className="topBar__logo"
                                onClick={() => {
                                    document.dispatchEvent(
                                        new CustomEvent('indexAncor', {
                                            detail: { name: 'index' },
                                        }),
                                    );

                                    dispatcher({
                                        type: 'mobileMenuShow',
                                        data: false,
                                    });
                                }}
                            >
                                <img
                                    src={require('../media/logo.svg').default}
                                    alt=""
                                    className="topBar__logoIcon"
                                />
                            </div>
                        </div>
                        <div
                            className={`topBar__menu ${mobileMenuShow ? '_active' : ''}`}
                            onClick={() => {
                                dispatcher({ type: 'mobileMenuShow', data: !mobileMenuShow });
                            }}
                        >
                            <div className="topBar__menuItem"></div>
                            <div className="topBar__menuItem"></div>
                        </div>
                        <div className="topBar__block _nav">
                            <div className="topBar__nav">
                                {getNav({ user }).map((item) => {
                                    const { name, isAncor } = item;
                                    const LinkTag = isAncor ? 'div' : Link;
                                    const linkProps = isAncor
                                        ? {
                                              onClick: () => {
                                                  document.dispatchEvent(
                                                      new CustomEvent('indexAncor', {
                                                          detail: { name },
                                                      }),
                                                  );
                                              },
                                          }
                                        : { pageName: name };
                                    const key = this.keys[name];
                                    const menuObj = mainContent.header?.menu || {};
                                    const menu = Object.keys(menuObj).map(
                                        (itemKey) => menuObj[itemKey],
                                    );
                                    const content =
                                        menu?.find((menuItem) => menuItem.url === key)?.title ||
                                        item.content;

                                    return (
                                        <div
                                            className={`topBar__navItem ${content ? '_ready' : ''}`}
                                            key={name}
                                            data-key={name}
                                        >
                                            <LinkTag className="topBar__navLink" {...linkProps}>
                                                {content}
                                            </LinkTag>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="topBar__block _buttons">
                            <div className="topBar__buttons">
                                <Link className="topBar__button" href="login">
                                    <Button className="_yellow _minSize">
                                        {mainContent?.header?.buttons?.button2?.title ||
                                            'Личный кабинет'}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <img
                            src={require('../media/icon-mob-lk.svg').default}
                            alt=""
                            className="topBar__icon"
                            onClick={() => {
                                changePage({ href: 'login' });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mobileMenuShow: state.mobileMenuShow,
        mainContent: state.mainContent,
        user: state.user,
        pages: state.pages,
    };
}

export default connect(mapStateToProps)(TopBar);

TopBar.propTypes = {
    mobileMenuShow: PropTypes.bool,
    mainContent: PropTypes.object,
    user: PropTypes.object,
    pages: PropTypes.object,
    isActive: PropTypes.bool,
    isInner: PropTypes.bool,
};
