import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Footer from '../../components/Footer.jsx';
import Button from '../../components/Button.jsx';

import setSpacesInText from '../../functions/setSpacesInText';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { device, components } = this.props;
        const app = components?.app;

        return (
            <>
                <div ref={this.parent} className={`indexMainPrizes ${components ? '_ready' : ''}`}>
                    <div className="indexMainPrizes__inner">
                        <div className="indexMainPrizes__head">
                            <div className="indexMainPrizes__headTitle _TITLE">
                                {app?.title?.title}
                            </div>
                            <p
                                className="indexMainPrizes__headDescription"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(app?.title?.description),
                                }}
                            ></p>
                        </div>
                        <div className="indexMainPrizes__app">
                            <div className="indexMainPrizes__appPhone">
                                <img
                                    src={app?.application?.thumb}
                                    alt=""
                                    className="indexMainPrizes__appPhoneImage"
                                />
                            </div>

                            <div className="indexMainPrizes__appBox">
                                <div className="indexMainPrizes__appQr">
                                    <img
                                        src={app?.qr?.thumb}
                                        alt=""
                                        className="indexMainPrizes__appQrImage"
                                    />
                                </div>
                                <div className="indexMainPrizes__appContent">
                                    {device === 'mobile' ? (
                                        <>скачай приложение</>
                                    ) : (
                                        <>Отсканируй QR-код для&nbsp;загрузки приложения</>
                                    )}
                                </div>
                            </div>
                        </div>
                        {device === 'mobile' && (
                            <>
                                <a
                                    className="indexMainPrizes__button"
                                    href={app?.[0]?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button className="_purple">{app?.[0]?.title}</Button>
                                </a>
                            </>
                        )}
                        <div className="indexMainPrizes__footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
